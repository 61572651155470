import React from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import {
  Box,
  PageHeaderV2,
  SectionWrapper,
  SEO,
  Theme,
  RibbonCTA,
} from "src/components";

import SmallCard from "./_components/_smallCard";
import MainCard from "./_components/_mainCard";
import TitleCard from "./_components/_titleCard";
import LargeCard from "./_components/_largeCard";

function FestivalSponsors({ data, setInvert, setInvertLogo }) {
  let festival = JSON.parse(data.festival.dataString);
  return (
    <>
      <SEO
        title={"Our Sponsors | " + RichText.asText(festival.title)}
        description={
          festival.opengraph_description &&
          RichText.asText(festival.opengraph_description)
        }
        image={
          festival.opengraph_image &&
          festival.opengraph_image.url &&
          festival.opengraph_image.url
        }
        imageAlt={
          festival.opengraph_image &&
          festival.opengraph_image.alt &&
          festival.opengraph_image.alt
        }
      />
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <PageHeaderV2
          heading={
            festival.sponsor_intro && RichText.asText(festival.sponsor_intro)
          }
          color="brand.primary"
          invert={true}
          setInvertLogo={setInvertLogo}
          setInvert={setInvert}
        />
      </ThemeProvider>
      {/* <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5)
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF"
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color
        })}
      > */}
      <>
        <SectionWrapper bg="bg.default">
          <Box mx="-7.5vw" pb={[7, 9]}>
            <Box mx={-1}>
              <TitleCard
                heading={
                  festival.title_sponsor_category_name &&
                  RichText.asText(festival.title_sponsor_category_name)
                }
                large={true}
              />
              {festival.title_sponsor_name && (
                <MainCard
                  heading={RichText.asText(festival.title_sponsor_name)}
                  image={festival.title_sponsor_logo}
                  href={festival.title_sponsor_link}
                />
              )}
              {/* <LargeCard image={festival.sponsor_cta_image} right /> */}
              {festival.sponsor_tiers.map((tier, index) => (
                <div
                  key={"sponsorTier" + index}
                  css={`
                    &:before,
                    &:after {
                      content: "";
                      display: table;
                    }
                    &:after {
                      clear: both;
                    }
                  `}
                >
                  {tier.primary.tier_cta_image &&
                    tier.primary.tier_cta_image.url &&
                    index % 2 === 0 && (
                      <LargeCard
                        image={tier.primary.tier_cta_image}
                        text={tier.primary.tier_cta_text}
                        left
                      />
                    )}
                  <TitleCard
                    heading={
                      tier.primary.tier_name &&
                      RichText.asText(tier.primary.tier_name)
                    }
                  />
                  {tier.primary.tier_cta_image &&
                    tier.primary.tier_cta_image.url &&
                    index % 2 === 1 && (
                      <LargeCard
                        image={tier.primary.tier_cta_image}
                        text={tier.primary.tier_cta_text}
                        right
                      />
                    )}
                  {tier.items.map((sponsor, sponsorIndex) => (
                    <SmallCard
                      image={sponsor.logo && sponsor.logo.url && sponsor.logo}
                      link={sponsor.link ? sponsor.link.url : "#"}
                      name={sponsor.name}
                      key={"sponsorTier" + index + "funder" + sponsorIndex}
                    />
                  ))}
                </div>
              ))}
            </Box>
          </Box>
        </SectionWrapper>
        {festival.sponsor_get_involved_title &&
          festival.sponsor_get_involved_title[0] && (
            <RibbonCTA
              heading={
                festival.sponsor_get_involved_title &&
                RichText.asText(festival.sponsor_get_involved_title)
              }
              buttonText={festival.sponsor_get_involved_button_text}
              buttonLink={festival.sponsor_get_involved_button_link}
              color="brand.primary"
              invert={true}
            />
          )}
      </>
      {/* </ThemeProvider> */}
    </>
  );
}

export default FestivalSponsors;

export const query = graphql`
  query ($id: String!) {
    festival: prismicFestival(id: { eq: $id }) {
      dataString
    }
  }
`;
