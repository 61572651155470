import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  MiniAccordion,
  Accordion,
  SectionHeader,
  RichText,
  SectionWrapper
} from "src/components";

const FAQs = ({ header, faqs }) => (
  <SectionWrapper id="faqs" pb={[8, 9, 10]} bg="bg.default">
    <SectionHeader heading={header} />
    {faqs && (
      <Accordion
        items={faqs.map(faqCategory => {
          return {
            id: faqCategory.id,
            heading:
              faqCategory.primary.category &&
              PrismicRichText.asText(faqCategory.primary.category),
            children: (
              <MiniAccordion
                items={faqCategory.items.map(faqItem => {
                  return {
                    id: faqItem.id,
                    heading: faqItem.question,
                    children: (
                      <RichText
                        children={PrismicRichText.render(
                          faqItem.answer,
                          linkResolver,
                          serializer
                        )}
                        color="text.body"
                      />
                    )
                  };
                })}
                color="text.default"
              />
            )
          };
        })}
        color="text.default"
      />
    )}
  </SectionWrapper>
);

export default FAQs;
