import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import {
  Box,
  Button,
  PrismicLink,
  RichText,
  SectionHeader,
  SectionWrapper,
  H6,
} from "src/components";

import { linkResolver, serializer } from "src/utils";

import Collage from "../../../_components/_collageSlice";

const Vendors = ({ page }) => (
  <>
    {page.vendors_description && page.vendors_description[0] && (
      <SectionWrapper
        maxWidth={0}
        pt={[8, 9]}
        pb={[4, 5]}
        bg="bg.default"
        id="vendors"
      >
        <SectionHeader heading={"Food Vendors"} />
        <RichText>
          {PrismicRichText.render(
            page.vendors_description,
            linkResolver,
            serializer
          )}
        </RichText>
      </SectionWrapper>
    )}
    {page.vendors_submission_button_text &&
      page.vendors_submission_button_link &&
      page.vendors_submission_button_link.link_type &&
      page.vendors_submission_open && (
        <SectionWrapper maxWidth={0} bg="bg.default">
          <Button
            as={PrismicLink}
            link={page.vendors_submission_button_link}
            children={page.vendors_submission_button_text}
            variant="primary"
          />
        </SectionWrapper>
      )}
    {(!page.vendors_submission_open ||
      !page.vendors_submission_button_text ||
      !page.vendors_submission_button_link ||
      !page.vendors_submission_button_link.link_type) && (
      <SectionWrapper maxWidth={0} bg="bg.default" pb={[8, 9]}>
        <H6
          children={`Applications are currently closed. Please check back later`}
        />
      </SectionWrapper>
    )}
    {page.vendors_collage &&
      page.vendors_collage[0] &&
      page.vendors_collage[0].image &&
      page.vendors_collage[0].image.url && (
        <Box pb={[4, 5]}>
          <Collage items={page.vendors_collage} />
        </Box>
      )}
  </>
);

export default Vendors;
