import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";

import { PageHeaderV2, SEO, RibbonCTA } from "src/components";

import Vendors from "./components/_vendors";
import ArtsMarkets from "./components/_artsMarket";
import FAQs from "./components/_faqs";

const ArtistsPage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        getInvolved: prismicGetInvolved {
          dataString
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.getInvolved.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={"Vendors"}
            description={
              page.vendors_description &&
              PrismicRichText.asText(page.vendors_description)
            }
          />
          <PageHeaderV2
            heading={`Vendors`}
            links={["Food Vendors", "Arts Market", "FAQs"]}
            color="brand.altPrimary"
            invert={true}
            image={page.vendors_image}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          {page.vendors_description && page.vendors_description[0] && (
            <Vendors page={page} />
          )}
          {page.arts_description && page.arts_description[0] && (
            <ArtsMarkets page={page} />
          )}
          <FAQs
            header={
              page.vendor_faq_header &&
              PrismicRichText.asText(page.vendor_faq_header)
            }
            faqs={page.body}
          />
          {page.vendor_cta_header && page.vendor_cta_header[0] && (
            <RibbonCTA
              heading={
                page.vendor_cta_header &&
                PrismicRichText.asText(page.vendor_cta_header)
              }
              body={
                page.vendor_cta_description &&
                PrismicRichText.render(
                  page.vendor_cta_description,
                  linkResolver,
                  serializer
                )
              }
              buttonText={page.vendor_cta_button_text}
              buttonLink={page.vendor_cta_button_link}
              color="brand.primary"
              invert={true}
            />
          )}
        </>
      );
    }}
  />
);

export default ArtistsPage;
