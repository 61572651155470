import React from "react";
import moment from "moment-timezone";

import {
  Box,
  Button,
  FlexRow,
  FlexCol,
  H6,
  H4,
  PrismicLink,
  Text,
} from "src/components";

function Sidebar({
  ticketType,
  onSaleDate,
  ticketState,
  ticketPrice,
  ticketLink,
}) {
  return (
    <Box
      mb={5}
      css={`
        border: 1px solid ${(props) => props.theme.colors.bg.alt};
        border-radius: 4px;
        padding: ${(props) => props.theme.space[4] + "px"};
      `}
    >
      <FlexCol mb={4}>
        <H6
          children={ticketType === "Tiered" ? "Starting At" : "Tickets"}
          textAlign="center"
        />
        {ticketPrice !== "$null" && (
          <FlexRow mt={4}>
            <FlexCol width={1}>
              <H4 children={ticketPrice} textAlign="center" />
              <Text children="Online" textAlign="center" mt={1} fontSize={1} />
            </FlexCol>
          </FlexRow>
        )}
      </FlexCol>
      {ticketLink && (
        <Button
          as={PrismicLink}
          link={ticketLink}
          children={
            ticketState !== "Sold Out"
              ? ticketPrice === "Free" || ticketPrice === "$null"
                ? "RSVP"
                : onSaleDate
                ? `Buy tickets`
                : `Buy tickets`
              : ticketState
          }
          width={1}
          variant="primary"
          disabled={
            ticketState === "Sold Out" ||
            ticketState === "Cancelled" ||
            ticketState === "Postponed"
              ? true
              : false
          }
          style={
            ticketState === "Sold Out" ||
            ticketState === "Cancelled" ||
            ticketState === "Postponed"
              ? { pointerEvents: "none", opacity: "0.5" }
              : {}
          }
        />
      )}
      <Text fontSize={0} mt={2} textAlign="center">
        With the exception of event cancellation, all sales are final.
      </Text>
    </Box>
  );
}

export default Sidebar;
