import React from "react";

import { RichText } from "src/components";

const About = ({ description }) => (
  <article>
    {description && (
      <RichText fontSize={[1, 2]} color="text.body">
        {description}
      </RichText>
    )}
  </article>
);

export default About;
