import React from "react";

import { Box, FlexGrid, SectionWrapper } from "src/components";

import About from "./_about";
import Opener from "./_opener";
import Sidebar from "./_sidebar";

const Main = ({
  ticketLink,
  ticketType,
  ticketState,
  ticketPrice,
  onSaleDate,
  featuredArtists,
  artistImage,
  artistName,
  artistDescription,
  description,
}) => (
  <SectionWrapper py={[7, 8]} bg="bg.default">
    <FlexGrid gutterX={[7, 8]}>
      <Box as="section" width={[1, null, 1 / 3]}>
        <Sidebar
          ticketType={ticketType}
          ticketLink={ticketLink}
          ticketPrice={ticketPrice}
          ticketState={ticketState}
          onSaleDate={onSaleDate}
        />
      </Box>
      <Box as="section" width={[1, null, 2 / 3]}>
        {artistImage && artistName && (
          <Opener
            artistName={artistName}
            artistImage={artistImage}
            artistDescription={artistDescription}
          />
        )}
        <About description={description} />
        {featuredArtists &&
          featuredArtists[0] &&
          featuredArtists[0].name &&
          featuredArtists.map(({ name, image, description }, index) => (
            <Box mt={[5, 7]}>
              <Opener
                artistName={name}
                artistImage={image}
                artistDescription={description}
                key={"featuredArtist" + index}
              />
            </Box>
          ))}
      </Box>
    </FlexGrid>
  </SectionWrapper>
);

export default Main;
