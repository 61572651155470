import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import { linkResolver, serializer } from "src/utils";

import {
  PageHeaderV2,
  RichText,
  PrismicImage,
  SectionWrapper,
  SEO,
  Select,
  H3,
  Text,
  FlexRow,
  Box,
  Theme
} from "src/components";

function PastLineupPage({ data, page, setInvert, setInvertLogo }) {
  const [festivals, setFestivals] = useState(undefined);
  const [activeFestival, setActiveFestival] = useState(undefined);
  const [activeYear, setActiveYear] = useState(new Date().getFullYear() - 1);

  useEffect(() => {
    //gather festival lineup data and order by year
    let festivals = [];
    if (page.body) {
      page.body.forEach(lineup => {
        let found = -1;
        festivals.forEach((festival, index) => {
          if (festival.name === lineup.primary.festival) {
            found = index;
          }
        });
        if (found !== -1) {
          festivals[found].years.push(
            <option value={lineup.primary.year}>{lineup.primary.year}</option>
          );
          festivals[found].formattedYears[lineup.primary.year] = lineup;
        } else {
          festivals.push({
            name: lineup.primary.festival,
            years: [
              <option value={lineup.primary.year}>{lineup.primary.year}</option>
            ],
            formattedYears: { [lineup.primary.year]: lineup }
          });
        }
      });
    }
    festivals.sort((a, b) => {
      if (a.name < b.name) {
        return 1;
      }
      if (b.name < a.name) {
        return -1;
      }
    });
    //find the active festival and open that first
    let activeFestivalData = {};
    let activeFestivalInitial = 0;
    if (data.activeFestival) {
      activeFestivalData = JSON.parse(data.activeFestival.dataString);
    }
    festivals.forEach((lineup, index) => {
      data.festivals.edges.forEach(edge => {
        if (
          activeFestivalData.ongoing_festival &&
          edge.node.uid === activeFestivalData.ongoing_festival.uid &&
          edge.node.data.title.text === lineup.name
        ) {
          activeFestivalInitial = index;
        }
        if (edge.node.data.title.text === lineup.name) {
          lineup.festival_color = edge.node.data.festival_color;
          lineup.festival_secondary_color =
            edge.node.data.festival_secondary_color;
          lineup.festival_tertiary_color =
            edge.node.data.festival_tertiary_color;
          lineup.theme = edge.node.data.theme;
        }
      });
    });
    setActiveFestival(activeFestivalInitial);
    setFestivals(festivals);
  }, []);

  useEffect(() => {
    if (activeFestival && !activeFestival[activeYear]) {
      setActiveYear(new Date().getFullYear() - 1);
    }
  }, [activeFestival]);

  return (
    <>
      <SEO
        image={
          page.opengraph_image &&
          page.opengraph_image.url &&
          page.opengraph_image.url
        }
        title={
          page.opengraph_title && PrismicRichText.asText(page.opengraph_title)
        }
        description={
          page.opengraph_description &&
          PrismicRichText.asText(page.opengraph_description)
        }
      />
      {festivals && (
        <>
          <ThemeProvider
            theme={Theme({
              bg:
                festivals[activeFestival].theme === "Dark theme"
                  ? {
                      default: "#1B1A19",
                      alt: lighten(0.05, "#1B1A19"),
                      wash: lighten(0.1, "#1B1A19"),
                      reverse: "#FFF"
                    }
                  : undefined,
              primary: festivals[activeFestival].festival_color,
              secondary: festivals[activeFestival].festival_secondary_color,
              tertiary: festivals[activeFestival].festival_tertiary_color
            })}
          >
            <>
              <PageHeaderV2
                heading={`Past Lineups`}
                color="brand.primary"
                invert={true}
                setInvertLogo={setInvertLogo}
                setInvert={setInvert}
              />
            </>
          </ThemeProvider>
          <ThemeProvider
            theme={Theme({
              text:
                festivals[activeFestival].theme === "Dark theme"
                  ? {
                      default: "#fff",
                      body: rgba("#fff", 0.75),
                      alt: rgba("#fff", 0.5),
                      reverse: "#1B1A19",
                      reverseBody: rgba("#1B1A19", 0.75),
                      reverseAlt: rgba("#1B1A19", 0.5)
                    }
                  : undefined,
              bg:
                festivals[activeFestival].theme === "Dark theme"
                  ? {
                      default: "#1B1A19",
                      alt: lighten(0.05, "#1B1A19"),
                      wash: lighten(0.1, "#1B1A19"),
                      reverse: "#FFF"
                    }
                  : undefined,
              primary: festivals[activeFestival].festival_color,
              secondary: festivals[activeFestival].festival_secondary_color,
              tertiary: festivals[activeFestival].festival_tertiary_color
            })}
          >
            <>
              <SectionWrapper maxWidth={0} pb={[3, 4, 5]} bg="brand.primary">
                <div
                  css={`
                    text-align: center;
                  `}
                >
                  <FlexRow mb={4} justifyContent="center">
                    {festivals.map((festival, index) => (
                      <H3
                        children={festival.name}
                        style={{
                          opacity: index === activeFestival ? 1 : 0.5,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setActiveFestival(index);
                        }}
                        ml={3}
                      />
                    ))}
                  </FlexRow>
                  <Select
                    value={activeYear}
                    children={festivals[activeFestival].years}
                    onChange={event => {
                      setActiveYear(event.target.value);
                    }}
                  />
                </div>
              </SectionWrapper>
              {festivals[activeFestival].formattedYears[activeYear] && (
                <>
                  {festivals[activeFestival].formattedYears[activeYear].primary
                    .poster && (
                    <section
                      css={`
                        position: relative;
                        z-index: 0;
                        padding: 0 ${props => props.theme.margin};
                        background-color: ${props =>
                          props.theme.colors.bg.default};
                        // Splits the image with a colored top and white bottom.
                        &::after {
                          content: "";
                          position: absolute;
                          right: 0;
                          top: 0;
                          left: 0;
                          z-index: -1;
                          height: 50%;
                          background-color: ${props =>
                            props.theme.colors.brand.primary};
                        }
                      `}
                    >
                      <div
                        css={`
                          width: 100%;
                          max-width: ${props => props.theme.maxWidth[0]};
                          margin: 0 auto;
                        `}
                      >
                        <PrismicImage
                          image={
                            festivals[activeFestival].formattedYears[activeYear]
                              .primary.poster
                          }
                          sizes="50vw"
                          key={activeFestival + "poster" + activeYear}
                        />
                      </div>
                    </section>
                  )}
                </>
              )}
              {festivals[activeFestival].formattedYears[activeYear] && (
                <>
                  {festivals[activeFestival].formattedYears[activeYear].primary
                    .description &&
                    festivals[activeFestival].formattedYears[activeYear].primary
                      .description[0] && (
                      <SectionWrapper
                        maxWidth={0}
                        pt={[8, 9, 10]}
                        pb={[4, 5, 6]}
                        bg="bg.default"
                      >
                        <RichText>
                          {PrismicRichText.render(
                            festivals[activeFestival].formattedYears[activeYear]
                              .primary.description,
                            linkResolver,
                            serializer
                          )}
                        </RichText>
                      </SectionWrapper>
                    )}
                  {festivals[activeFestival].formattedYears[activeYear].primary
                    .artist_list &&
                    festivals[activeFestival].formattedYears[activeYear].primary
                      .artist_list[0] && (
                      <SectionWrapper
                        maxWidth={0}
                        pt={[4, 5, 6]}
                        pb={[8, 9, 10]}
                        bg="bg.wash"
                      >
                        {festivals[activeFestival].formattedYears[
                          activeYear
                        ].primary.artist_list
                          .sort((a, b) => a.text - b.text)
                          .map((artist, index) => (
                            <Box
                              borderBottom="1px solid"
                              borderColor="text.alt"
                              key={
                                activeFestival +
                                "poster" +
                                activeYear +
                                "artists" +
                                artist.name +
                                index
                              }
                              py={[1, 2]}
                            >
                              <Text children={artist.text} />
                            </Box>
                          ))}
                      </SectionWrapper>
                    )}
                </>
              )}
            </>
          </ThemeProvider>
        </>
      )}
    </>
  );
}

function PastLineupPageOuter({ preview, ...rest }) {
  return (
    <StaticQuery
      query={graphql`
        {
          activeFestival: prismicActiveFestival {
            dataString
          }
          pastLineups: prismicPastLineups {
            dataString
          }
          festivals: allPrismicFestival {
            edges {
              node {
                uid
                data {
                  theme
                  festival_color
                  festival_secondary_color
                  festival_tertiary_color
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        let page = JSON.parse(data.pastLineups.dataString);
        if (preview) {
          page = preview;
        }

        return <PastLineupPage data={data} page={page} {...rest} />;
      }}
    />
  );
}

export default PastLineupPageOuter;
