import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { SEO } from "src/components";

import Intro from "./_components/_intro";
import Main from "./_components/_main";

const EventPage = ({ data, setInvert, setInvertLogo, preview }) => {
  let event = {};
  let supportingArtist = {};
  let featuredArtists = [];
  if (!data) {
    data = preview;
  }
  if (data) {
    if (data.event) {
      if (data.event.dataString) {
        event = JSON.parse(data.event.dataString);
      }
    }
  }
  if (event.artist) {
    //find opener data
    data.artists.edges.forEach((outerArtist) => {
      if (event.artist.id === outerArtist.node.prismicId) {
        supportingArtist = JSON.parse(outerArtist.node.dataString);
      }
    });
  }
  if (event.featured_artists) {
    //find featured artist data
    data.artists.edges.forEach((outerArtist) => {
      event.featured_artists.forEach((innerArtist) => {
        if (
          innerArtist &&
          innerArtist.featured_artist &&
          innerArtist.featured_artist.id === outerArtist.node.prismicId
        ) {
          const artistData = JSON.parse(outerArtist.node.dataString);
          featuredArtists.push({
            name:
              artistData.artist_name && RichText.asText(artistData.artist_name),
            image: artistData.image && artistData.image,
            description:
              artistData.bio &&
              RichText.render(artistData.bio, linkResolver, serializer),
          });
        }
      });
    });
  }
  return (
    <>
      <SEO
        image={event && event.image && event.image.url && event.image.url}
        imageAlt={event && event.image && event.image.alt && event.image.alt}
        title={event.title && RichText.asText(event.title)}
        image={event.image && event.image.url}
      />
      <Intro
        title={event.title && RichText.asText(event.title)}
        featuring={
          supportingArtist.artist_name &&
          RichText.asText(supportingArtist.artist_name)
        }
        date={event.date}
        image={event.image && event.image}
        startTime={event.start_time}
        endTime={event.end_time}
        venue={event.venue}
        setInvertLogo={setInvertLogo}
        setInvert={setInvert}
      />
      <Main
        artistName={
          supportingArtist.artist_name &&
          RichText.asText(supportingArtist.artist_name)
        }
        artistImage={supportingArtist.image && supportingArtist.image}
        artistDescription={
          supportingArtist.bio &&
          RichText.render(supportingArtist.bio, linkResolver, serializer)
        }
        featuredArtists={featuredArtists}
        onSaleDate={event.on_sale_date}
        ticketLink={
          event.ticket_link && event.ticket_link.url && event.ticket_link
        }
        ticketPrice={event.ticket_price === 0 ? "Free" : event.ticket_price}
        ticketState={event.ticket_state}
        ticketType={event.ticket_price_type}
        description={
          event.description &&
          RichText.render(event.description, linkResolver, serializer)
        }
      />
    </>
  );
};

export default EventPage;

export const query = graphql`
  query ($id: String!) {
    event: prismicEvent(id: { eq: $id }) {
      prismicId
      dataString
    }
    artists: allPrismicArtist {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
  }
`;
