import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import {
  Box,
  Button,
  PrismicLink,
  RichText,
  SectionHeader,
  SectionWrapper,
  H6,
} from "src/components";

import { linkResolver, serializer } from "src/utils";

import Collage from "../../../_components/_collageSlice";

const ArtsMarket = ({ page }) => (
  <>
    {page.arts_description && page.arts_description[0] && (
      <SectionWrapper maxWidth={0} pb={[4, 5]} bg="bg.default" id="arts-market">
        <SectionHeader heading={"Arts Market"} />
        <RichText>
          {PrismicRichText.render(
            page.arts_description,
            linkResolver,
            serializer
          )}
        </RichText>
      </SectionWrapper>
    )}
    {page.arts_submission_button_text &&
      page.arts_submission_button_link &&
      page.arts_submission_button_link.link_type &&
      page.arts_submission_open && (
        <SectionWrapper maxWidth={0} bg="bg.default">
          <Button
            as={PrismicLink}
            link={page.arts_submission_button_link}
            children={page.arts_submission_button_text}
            variant="primary"
          />
        </SectionWrapper>
      )}
    {(!page.arts_submission_open ||
      !page.arts_submission_button_text ||
      !page.arts_submission_button_link ||
      !page.arts_submission_button_link.link_type) && (
      <SectionWrapper maxWidth={0} bg="bg.default">
        <H6
          children={`Applications are currently closed. Please check back later`}
        />
      </SectionWrapper>
    )}
    {page.arts_collage &&
      page.arts_collage[0] &&
      page.arts_collage[0].image &&
      page.arts_collage[0].image.url && (
        <Box py={[4, 5]}>
          <Collage items={page.arts_collage} />
        </Box>
      )}
  </>
);

export default ArtsMarket;
