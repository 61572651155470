import React from "react";
import styled, { css } from "styled-components";
import { cover } from "polished";

import { PrismicImage, RichText } from "src/components";

const Wrapper = styled.div`
  position: relative;
  width: 50%;
  height: 900px;
  ${props =>
    props.right &&
    css`
      float: right;
    `};
  ${props =>
    props.left &&
    css`
      float: left;
    `};
  @media (max-width: 64em) {
    height: 675px;
  }
  @media (max-width: 52em) {
    width: 100%;
    height: 450px;
  }
`;

const Inner = styled.div`
  ${cover()};
  background-color: ${props => props.theme.colors.bg.alt};
`;

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 128px 32px 32px;
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.01) 8.1%,
    hsla(0, 0%, 0%, 0.036) 15.5%,
    hsla(0, 0%, 0%, 0.078) 22.5%,
    hsla(0, 0%, 0%, 0.132) 29%,
    hsla(0, 0%, 0%, 0.194) 35.3%,
    hsla(0, 0%, 0%, 0.264) 41.2%,
    hsla(0, 0%, 0%, 0.338) 47.1%,
    hsla(0, 0%, 0%, 0.412) 52.9%,
    hsla(0, 0%, 0%, 0.486) 58.8%,
    hsla(0, 0%, 0%, 0.556) 64.7%,
    hsla(0, 0%, 0%, 0.618) 71%,
    hsla(0, 0%, 0%, 0.672) 77.5%,
    hsla(0, 0%, 0%, 0.714) 84.5%,
    hsla(0, 0%, 0%, 0.74) 91.9%,
    hsla(0, 0%, 0%, 0.75) 100%
  );
`;

const LargeCard = ({ right, left, image, text }) => (
  <Wrapper right={right} left={left}>
    <Inner>
      {image && (
        <PrismicImage image={image} style={{ ...cover() }} sizes="10vw" />
      )}
      {text && (
        <Container>
          <RichText fontSize={1.5} fluid color="text.reverse">
            <p>{text}</p>
          </RichText>
        </Container>
      )}
    </Inner>
  </Wrapper>
);

export default LargeCard;
