import React from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { SEO, Theme, PageHeaderV2, RibbonCTA } from "src/components";

import Intro from "./_components/_intro";
import FAQ from "./_components/_faq";

const FestivalFAQPage = ({ data, setInvert, setInvertLogo }) => {
  let festival = {};
  if (data.festival) {
    if (data.festival.dataString) {
      festival = JSON.parse(data.festival.dataString);
      festival.uid = data.festival.uid;
    }
  }

  return (
    <>
      <SEO
        title={
          "Frequently asked Questions |  " +
          (festival.title && RichText.asText(festival.title))
        }
        description={
          festival.about_intro_description &&
          RichText.asText(festival.about_intro_description)
        }
        image={
          festival.opengraph_image &&
          festival.opengraph_image.url &&
          festival.opengraph_image.url
        }
        imageAlt={
          festival.opengraph_image &&
          festival.opengraph_image.alt &&
          festival.opengraph_image.alt
        }
      />
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        {festival.faqs_heading && festival.faqs_heading[0] && (
          <PageHeaderV2
            image={festival.faqs_image}
            heading={RichText.asText(festival.faqs_heading)}
            links={["FAQS"]}
            color="brand.primary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
        )}
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          {festival.faqs_description &&
            festival.faqs_description[0] &&
            festival.faqs_description[0].text && (
              <Intro
                description={
                  festival.faqs_description &&
                  festival.faqs_description[0] &&
                  RichText.render(
                    festival.faqs_description,
                    linkResolver,
                    serializer
                  )
                }
              />
            )}
          {festival.body2 && festival.body2.length > 0 && (
            <FAQ faqs={festival.body2} />
          )}
          {festival.faqs_cta_heading && festival.faqs_cta_heading[0] && (
            <RibbonCTA
              heading={
                festival.faqs_cta_heading &&
                RichText.asText(festival.faqs_cta_heading)
              }
              body={festival.faqs_cta_subheading}
              buttonText={festival.faqs_cta_button_text}
              buttonLink={festival.faqs_cta_button_link}
              color="brand.tertiary"
              invert={true}
            />
          )}
        </>
      </ThemeProvider>
    </>
  );
};

export default FestivalFAQPage;

export const query = graphql`
  query ($id: String!) {
    festival: prismicFestival(id: { eq: $id }) {
      prismicId
      uid
      dataString
    }
  }
`;
