import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  MiniAccordion,
  Accordion,
  Box,
  SectionHeader,
  RichText,
  SectionWrapper
} from "src/components";

const Content = ({ children }) => (
  <Box mt="16px" mb="16px" ml="44px">
    <RichText fontSize={1} fluid>
      {children}
    </RichText>
  </Box>
);

const FAQs = ({ header, faqs }) => (
  <SectionWrapper
    id="faqs"
    py={[8, 9, 10]}
    bg="bg.default"
    css={`
      border-top: 1px solid ${props => props.theme.colors.bg.alt};
    `}
  >
    <SectionHeader
      heading={header}
      body="We have answers to the most common questions about volunteering."
    />
    {faqs && (
      <Accordion
        items={faqs.map(faqCategory => {
          return {
            id: faqCategory.id,
            heading:
              faqCategory.primary.category &&
              PrismicRichText.asText(faqCategory.primary.category),
            children: (
              <MiniAccordion
                items={faqCategory.items.map(faqItem => {
                  return {
                    id: faqItem.id,
                    heading: faqItem.question,
                    children: (
                      <RichText
                        children={PrismicRichText.render(
                          faqItem.answer,
                          linkResolver,
                          serializer
                        )}
                        color="text.body"
                      />
                    )
                  };
                })}
                color="text.default"
              />
            )
          };
        })}
        color="text.default"
      />
    )}
  </SectionWrapper>
);

export default FAQs;
