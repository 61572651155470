import React from "react";
import moment from "moment-timezone";

import {
  Box,
  H1,
  H3,
  H4,
  PrismicImage,
  SplitHeader,
  Text
} from "src/components";

function Intro({
  featuring,
  startTime,
  endTime,
  title,
  image,
  venue,
  setInvert,
  setInvertLogo
}) {
  return (
    <SplitHeader
      left={
        <>
          {image && (
            <Box position="relative" bg="bg.reverse">
              <PrismicImage image={image} alt={title && title} sizes="33vw" />
            </Box>
          )}
        </>
      }
      right={
        <div
          css={`
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            height: 100%;
            ${props => props.theme.mediaQueries.large} {
              align-items: flex-start;
              text-align: left;
            }
          `}
        >
          {startTime && (
            <H4
              children={
                moment(startTime)
                  .tz("America/Edmonton")
                  .format("MMMM Do h:mm A") +
                (endTime
                  ? " - " +
                    (moment(endTime)
                      .tz("America/Edmonton")
                      .dayOfYear() ===
                    moment(startTime)
                      .tz("America/Edmonton")
                      .dayOfYear()
                      ? moment(endTime)
                          .tz("America/Edmonton")
                          .format("h:mm A")
                      : moment(endTime)
                          .tz("America/Edmonton")
                          .format("MMMM Do h:mm A"))
                  : "")
              }
              color="text.reverse"
            />
          )}
          {title && <H1 children={title} mt={4} color="text.reverse" />}
          {featuring && (
            <H3 children={"With " + featuring} mt={2} color="text.reverse" />
          )}
          {venue && (
            <Text
              mt={3}
              fontSize={[0, 1]}
              textAlign={["center", null, null, "initial"]}
              color="text.reverse"
            >
              <span>{venue}</span>
            </Text>
          )}
        </div>
      }
      color="brand.altPrimary"
      invert={true}
      setInvertLogo={setInvertLogo}
      setInvert={setInvert}
    />
  );
}

export default Intro;
