import React from "react";
import { cover } from "polished";

import {
  FlexGrid,
  FlexCol,
  PrismicImage,
  Box,
  H6,
  Text,
  RichText,
} from "src/components";

const Opener = ({ artistName, artistImage, artistDescription }) => (
  <Box mb={[4, 5]} bg="bg.reverse">
    <FlexGrid gutterX={[3, 5]}>
      <FlexCol width={[1 / 3]}>
        {artistImage && (
          <FlexCol position="relative" flex={1}>
            <div
              css={`
                ${cover()};
              `}
            >
              <PrismicImage image={artistImage} style={{ height: "100%" }} />
            </div>
          </FlexCol>
        )}
      </FlexCol>
      <FlexCol width={[2 / 3]} justifyContent="center">
        <Box py={[3, 5, 7]}>
          <H6 children={"Featuring"} color="text.reverse" />
          <Text fontSize={4} fontWeight={600} color="text.reverse">
            {artistName}
          </Text>
        </Box>
      </FlexCol>
      {artistDescription && (
        <FlexCol width={1} m={[3, 4]}>
          <article>
            <RichText fontSize={[1, 2]} color="text.reverse">
              {artistDescription}
            </RichText>
          </article>
        </FlexCol>
      )}
    </FlexGrid>
  </Box>
);

export default Opener;
