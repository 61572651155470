import React from "react";
import styled from "styled-components";
import { cover, rgba } from "polished";

import { PrismicImage, H4 } from "src/components";

const Wrapper = styled.div`
  position: relative;
  width: 25%;
  height: 225px;
  float: left;
  background-color: ${props => rgba(props.theme.colors.bg.reverse, 0.125)};
  user-select: none;
  @media (max-width: 52em) {
    width: 50%;
    height: 180px;
  }
`;

const Inner = styled.div`
  ${cover()};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  padding: 8px;
  background-color: ${props => props.theme.colors.bg.default};
`;

const SmallCard = ({ name, image, link }) => (
  <Wrapper>
    {name && (
      <a href={link} title={name} target="_blank" rel="noopener noreferrer">
        <Inner>
          {name && !image && <H4 children={name} fluid textAlign="center" />}
          {image && (
            <PrismicImage
              image={image}
              sizes="5vw"
              style={{ height: "75%", width: "75%" }}
              alt={name}
              imgStyle={{ objectFit: "contain" }}
            />
          )}
        </Inner>
      </a>
    )}
  </Wrapper>
);

export default SmallCard;
