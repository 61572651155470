import React from "react";

import { H2, RichText, SectionWrapper } from "src/components";

const Intro = ({ description }) => (
  <>
    {description && (
      <SectionWrapper py={[6, 7, 8]} maxWidth={0} bg="bg.default" id="about">
        {description && (
          <RichText textAlign="center" fontSize={[1, 2]} color="text.body">
            {description}
          </RichText>
        )}
      </SectionWrapper>
    )}
  </>
);

export default Intro;
