import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import qs from "qs";
import Prismic from "prismic-javascript";

import { FlexCol, H1, H6, RadioButton } from "src/components";

import Home from "../index";
import Contact from "../contact";
import News from "../news";
import Announcement from "../_announcement/_index";
import Post from "../_post/_index";
import FestivalHall from "../festival-hall";
import Concerts from "../concerts";
import Concert from "../_concert/_index";
import Event from "../_event/_index";
import About from "../about/index";
import PastLineups from "../past-lineups/";

import GetInvolvedDonate from "../get-involved/donate";
import GetInvolvedArtists from "../get-involved/artists";
import GetInvolvedMedia from "../get-involved/media";
import GetInvolvedVendors from "../get-involved/vendors";
import GetInvolvedSponsor from "../get-involved/sponsor";
import GetInvolvedVolunteer from "../get-involved/volunteer";
import GetInvolvedInsidersClub from "../get-involved/_insiders-club/_index";

import FestivalAbout from "../_festivalAbout/_index";
import FestivalSchedule from "../_festivalSchedule/_index";
import FestivalLineup from "../_festivalLineup/_index";
import FestivalTickets from "../_festivalTickets/_index";
import FestivalAtTheFestival from "../_festivalAt/_index";
import FestivalPlanYourFestival from "../_festivalPlan/_index";
import FestivalSponsors from "../_festivalSponsors/_index";
import FestivalMap from "../_festivalMap/_index";
import FestivalFAQPage from "../_festivalFAQ/_index";

const linkResolver = function (doc) {
  return doc;
};

const festivalOptions = [
  { value: "Schedule", label: "Schedule" },
  { value: "Lineup", label: "Lineup" },
  { value: "Tickets", label: "Tickets" },
  { value: "At The Festival", label: "At The Festival" },

  { value: "Plan Your Festival", label: "Plan Your Festival" },
  { value: "Sponsors", label: "Sponsors" },
  { value: "Map", label: "Map" },
  { value: "About", label: "About" },
  { value: "FAQ", label: "FAQ" },
];

const InvolvedOptions = [
  { value: "Sponsor", label: "Sponsor" },
  { value: "Volunteer", label: "Volunteer" },
  { value: "Donate", label: "Donate" },
  { value: "Insiders' Club", label: "Insiders' Club" },
  { value: "Artists", label: "Artists" },
  { value: "Vendors", label: "Vendors" },
  { value: "Media", label: "Media" },
];

function Preview(props) {
  const [loading, setLoading] = useState(true);
  const [involvedPage, setInvolvedPage] = useState("Volunteer");
  const [festivalPage, setFestivalPage] = useState("Schedule");
  const [preview, setPreview] = useState({});

  useEffect(() => {
    loadPreview();
  }, []);

  const loadPreview = () => {
    const params = qs.parse(props.location.search.slice(1));
    Prismic.getApi(
      "https://calgary-folk-music-festival.prismic.io/api/v2"
    ).then((api) => {
      api.previewSession(params.token, linkResolver, "/").then((document) => {
        setPreview(document);
        setLoading(false);
      });
    });
  };

  return (
    <>
      {loading && (
        <FlexCol height="100vh" justifyContent="center" alignItems="center">
          <H1>Loading previews...</H1>
        </FlexCol>
      )}
      {!loading && (
        <StaticQuery
          query={graphql`
            {
              artists: allPrismicArtist {
                edges {
                  node {
                    prismicId
                    uid
                    dataString
                  }
                }
              }
              stages: allPrismicStage {
                edges {
                  node {
                    prismicId
                    dataString
                  }
                }
              }
            }
          `}
          render={(data) => {
            return (
              <>
                {preview.type === "home" && (
                  <Home preview={preview.data} {...props} />
                )}
                {preview.type === "about" && (
                  <About preview={preview.data} {...props} />
                )}
                {preview.type === "past-lineups" && (
                  <PastLineups preview={preview.data} {...props} />
                )}
                {preview.type === "contact" && (
                  <Contact preview={preview.data} {...props} />
                )}
                {preview.type === "news" && (
                  <News preview={preview.data} {...props} />
                )}
                {preview.type === "announcement" && (
                  <Announcement preview={preview.data} {...props} />
                )}
                {preview.type === "post" && (
                  <Post preview={preview.data} {...props} />
                )}
                {preview.type === "festival_hall" && (
                  <FestivalHall preview={preview.data} {...props} />
                )}
                {preview.type === "concerts" && (
                  <Concerts preview={preview.data} {...props} />
                )}
                {preview.type === "concert_series" && (
                  <Concerts seriesPreview={preview.data} {...props} />
                )}
                {preview.type === "concer" && (
                  <Concert
                    {...props}
                    data={undefined}
                    preview={{
                      artists: data.artists,
                      concert: { dataString: JSON.stringify(preview.data) },
                    }}
                  />
                )}
                {preview.type === "event" && (
                  <Event
                    {...props}
                    data={undefined}
                    preview={{
                      artists: data.artists,
                      event: { dataString: JSON.stringify(preview.data) },
                    }}
                  />
                )}
                {preview.type === "get_involved" && (
                  <>
                    <FlexCol
                      bg="bg.reverse"
                      p={"2.5vh"}
                      position="fixed"
                      bottom={0}
                      left={0}
                      right={0}
                    >
                      <H6
                        children={"Previewing Get Involved"}
                        color="text.reverse"
                        mb={2}
                      />
                      <RadioButton
                        options={InvolvedOptions}
                        value={involvedPage}
                        valueProp="value"
                        labelProp="label"
                        onChange={setInvolvedPage}
                      />
                    </FlexCol>
                    {involvedPage === "Media" && (
                      <GetInvolvedMedia preview={preview.data} {...props} />
                    )}
                    {involvedPage === "Donate" && (
                      <GetInvolvedDonate preview={preview.data} {...props} />
                    )}
                    {involvedPage === "Sponsor" && (
                      <GetInvolvedSponsor preview={preview.data} {...props} />
                    )}
                    {involvedPage === "Volunteer" && (
                      <GetInvolvedVolunteer preview={preview.data} {...props} />
                    )}
                    {involvedPage === "Insiders' Club" && (
                      <GetInvolvedInsidersClub
                        preview={preview.data}
                        {...props}
                      />
                    )}
                    {involvedPage === "Artists" && (
                      <GetInvolvedArtists preview={preview.data} {...props} />
                    )}
                    {involvedPage === "Vendors" && (
                      <GetInvolvedVendors preview={preview.data} {...props} />
                    )}
                  </>
                )}
                {preview.type === "festival" && (
                  <>
                    <FlexCol
                      bg="bg.reverse"
                      p={"2.5vh"}
                      position="fixed"
                      bottom={0}
                      left={0}
                      right={0}
                      zIndex={999999}
                    >
                      <H6
                        children={
                          "Previewing " + preview.data.title &&
                          preview.data.title[0].text
                        }
                        color="text.reverse"
                        mb={2}
                      />
                      <RadioButton
                        options={festivalOptions}
                        value={festivalPage}
                        valueProp="value"
                        labelProp="label"
                        onChange={setFestivalPage}
                      />
                    </FlexCol>
                    {festivalPage === "About" && (
                      <FestivalAbout
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {festivalPage === "Schedule" && (
                      <FestivalSchedule
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {festivalPage === "Lineup" && (
                      <FestivalLineup
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {festivalPage === "Tickets" && (
                      <FestivalTickets
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {/* TEMP CHANGE FOR SUMMER SERENADES */}
                    {festivalPage === "At The Festival" && (
                      <FestivalAtTheFestival
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {/* TEMP CHANGE FOR SUMMER SERENADES */}
                    {festivalPage === "Plan Your Festival" && (
                      <FestivalPlanYourFestival
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {festivalPage === "Sponsors" && (
                      <FestivalSponsors
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {/* TEMP CHANGE FOR SUMMER SERENADES */}
                    {festivalPage === "FAQ" && (
                      <FestivalFAQPage
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                    {festivalPage === "Map" && (
                      <FestivalMap
                        {...props}
                        data={{
                          festival: {
                            uid: preview.uid,
                            id: preview.id,
                            dataString: JSON.stringify(preview.data),
                          },
                          artists: data.artists,
                          stages: data.stages,
                        }}
                        preview={true}
                      />
                    )}
                  </>
                )}
              </>
            );
          }}
        />
      )}
    </>
  );
}

export default Preview;
