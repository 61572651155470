import React from "react";
import moment from "moment-timezone";
import { mdiArrowTopRight } from "@mdi/js";
import { StaticQuery, graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";

import {
  Box,
  Button,
  FlexGrid,
  H3,
  H6,
  PrismicImage,
  PrismicLink,
  PageHeaderV2,
  RichText,
  SectionWrapper,
  SectionHeader,
  SEO,
  RibbonCTA,
  FlexRow,
  Icon,
  Text
} from "src/components";

const Level = ({ name, price, image, description, joinLink, useLink }) => (
  <SectionWrapper
    id={name && name.replace(/ /g, "-").toLowerCase()}
    maxWidth={1}
    pb={[8, 9, 10]}
    bg="bg.default"
  >
    <FlexGrid gutterX={[5, 5, 7]} gutterY={[5]}>
      <Box width={[1, 1 / 3]}>{image && <PrismicImage image={image} />}</Box>
      <Box width={[1, 2 / 3]}>
        <H3 children={name} mb={[2, 3]} />
        <H6 children={price} mb={[4, 5]} color="text.body" />
        <RichText>{description}</RichText>
        {joinLink && useLink !== "No" && (
          <div
            css={`
              margin-top: 32px;
            `}
          >
            <Button
              children={`Join now`}
              variant="primary"
              as={PrismicLink}
              link={joinLink}
            />
          </div>
        )}
      </Box>
    </FlexGrid>
  </SectionWrapper>
);

const InsidersClubPage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        getInvolved: prismicGetInvolved {
          dataString
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.getInvolved.dataString);
      if (preview) {
        page = preview;
      }
      let tiers = [];
      if (page.tiers) {
        page.tiers.forEach(tier => {
          if (tier.tier_name && tier.tier_name[0]) {
            tiers.push(tier.tier_name[0].text);
          }
        });
        tiers.push("Events");
        tiers.push("Contact");
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={"Insiders' Club"}
            description={
              page.insiders_club_description &&
              PrismicRichText.asText(page.insiders_club_description)
            }
          />
          <PageHeaderV2
            heading={`Insiders' Club`}
            links={tiers}
            color="brand.altPrimary"
            invert={true}
            image={page.insiders_club_image}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          {page.insiders_club_description && page.insiders_club_description[0] && (
            <SectionWrapper maxWidth={0} py={[8, 9, 10]} bg="bg.default">
              <RichText>
                {PrismicRichText.render(
                  page.insiders_club_description,
                  linkResolver,
                  serializer
                )}
              </RichText>
            </SectionWrapper>
          )}
          {page.tiers && (
            <>
              {page.tiers.map((tier, index) => (
                <Level
                  key={"clubtiers" + index}
                  name={PrismicRichText.asText(tier.tier_name)}
                  price={tier.tier_price}
                  description={
                    tier.tier_description
                      ? PrismicRichText.render(
                          tier.tier_description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  image={tier.tier_image}
                  joinLink={page.insiders_club_join_link}
                  useLink={tier.use_join_link}
                />
              ))}
            </>
          )}
          {page.insiders_club_events_title && page.insiders_club_events && (
            <SectionWrapper
              id="events"
              py={[8, 9, 10]}
              bg="bg.default"
              css={`
                border-top: 1px solid ${props => props.theme.colors.bg.alt};
              `}
            >
              <SectionHeader
                heading={
                  page.insiders_club_events_title &&
                  PrismicRichText.asText(page.insiders_club_events_title)
                }
                body={page.insiders_club_events_subtitle}
                invert={false}
              />
              {page.insiders_club_events.map((event, index) => (
                <FlexRow
                  key={"insiderClubEvent" + index}
                  as={event.link && event.link.id ? PrismicLink : "div"}
                  link={event.link && event.link.id && event.link}
                  css={`
                    border-bottom: 1px solid
                      ${props => props.theme.colors.bg.alt};
                  `}
                  alignItems="center"
                  justifyContent="space-between"
                  mb={[2]}
                >
                  <Box py={2} mr={3}>
                    <Text children={event.label} fontSize={3} mb={3} />
                    {event.date && (
                      <H6
                        children={moment(event.date)
                          .tz("America/Edmonton")
                          .format("MMMM Do")}
                      />
                    )}
                  </Box>
                  {event.link && event.link.id && (
                    <Icon symbol={mdiArrowTopRight} size={2} />
                  )}
                </FlexRow>
              ))}
            </SectionWrapper>
          )}
          <RibbonCTA
            id="contact"
            heading={
              page.insiders_club_cta_title &&
              PrismicRichText.asText(page.insiders_club_cta_title)
            }
            body={page.insiders_club_cta_subtext}
            buttonText={page.insiders_club_cta_button_text}
            buttonLink={page.insiders_club_cta_button_link}
            color="brand.primary"
            invert={true}
          />
        </>
      );
    }}
  />
);

export default InsidersClubPage;
