import React from "react";

import {
  Box,
  FlexCol,
  FlexGrid,
  H3,
  H4,
  PrismicLink,
  RichText,
  SectionWrapper,
  SmallButton,
  Text,
} from "src/components";

const Intro = ({
  subtitle,
  description,
  applicationsOpen,
  joinHeader,
  joinDescription,
  joinLink,
  returningHeader,
  returningDescription,
  returningLink,
}) => (
  <>
    <SectionWrapper id="join" maxWidth={0} pt={7} pb={[5, 5, 7]}>
      <FlexCol width={[1]} textAlign="center">
        {subtitle && <H3 children={subtitle} mb={4} />}
        {description && (
          <RichText fontSize={[1, 2]} color="text.body">
            {description}
          </RichText>
        )}
      </FlexCol>
    </SectionWrapper>
    {/* {((joinHeader && joinHeader[0]) ||
      (returningHeader && returningHeader[0])) && (
      <SectionWrapper pb={[8, 9, 10]}>
        <Box p={[5, 7]} bg="bg.wash">
          <FlexGrid gutterX={[7]} gutterY={[7]}>
            <FlexCol
              width={[1, null, 1 / 2]}
              css={`
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                ${(props) => props.theme.mediaQueries.medium} {
                  border-right: 1px solid rgba(0, 0, 0, 0.125);
                  border-bottom: 0;
                }
              `}
            >
              {joinHeader && joinHeader[0] && (
                <H4 children={joinHeader} textAlign="center" />
              )}
              {joinDescription && (
                <RichText
                  mt={2}
                  fontSize={[1, 2]}
                  textAlign="center"
                  color="text.body"
                >
                  {joinDescription}
                </RichText>
              )}
              {joinLink && applicationsOpen && (
                <Box mt={4} textAlign="center">
                  <SmallButton
                    as={PrismicLink}
                    link={joinLink}
                    children={`Join the Waitlist`}
                    variant="defaultAlt"
                  />
                </Box>
              )}
              {!applicationsOpen && (
                <Text mt={2} fontSize={0}>
                  Applications are not currently open. Check back later!
                </Text>
              )}
            </FlexCol>
            <FlexCol width={[1, null, 1 / 2]}>
              {returningHeader && returningHeader[0] && (
                <H4 children={returningHeader} textAlign="center" />
              )}
              {returningDescription && (
                <RichText
                  mt={2}
                  fontSize={[1, 2]}
                  textAlign="center"
                  color="text.body"
                >
                  {returningDescription}
                </RichText>
              )}
              {returningLink && applicationsOpen && (
                <Box mt={4} textAlign="center">
                  <SmallButton
                    as={PrismicLink}
                    link={returningLink}
                    children={`Login to volunteer portal`}
                    variant="defaultAlt"
                  />
                </Box>
              )}
              {!applicationsOpen && (
                <Text mt={2} fontSize={0}>
                  Applications are not currently open. Check back later!
                </Text>
              )}
            </FlexCol>
          </FlexGrid>
        </Box>
      </SectionWrapper>
    )} */}
  </>
);

export default Intro;
