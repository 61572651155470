import React, { useState } from "react";
import moment from "moment-timezone";

import {
  Accordion,
  Box,
  FlexCol,
  FlexGrid,
  SectionHeader,
  H4,
  RadioButton,
  SectionWrapper,
  SmallButton,
  Text,
} from "src/components";

const Date = ({ date, label, file, fileLabel }) => (
  <FlexCol width={[1 / 1, 1 / 2, null, 1 / 4]}>
    <FlexCol p={[3, 5]} bg="bg.alt" height="100%">
      {date && <H4 children={date} />}
      {label && <Text children={label} mt={1} />}
      {file && (
        <Box mt={3}>
          <SmallButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href={file}
            children={fileLabel ? fileLabel : `More info`}
            variant="defaultAlt"
          />
        </Box>
      )}
    </FlexCol>
  </FlexCol>
);

function CTA({
  volunteerDates,
  volunteerDocuments,
  coordinatorDates,
  coordinatorDocuments,
  managerDates,
  managerDocuments,
  newsletters,
  coordinatorNewsletters,
  managerNewsletters,
  volunteerTitle,
  managerTitle,
  coordinatorTitle,
}) {
  const [dateType, setDateType] = useState(volunteerTitle);

  return (
    <Box
      id="resources"
      py={[8, 9, 10]}
      bg="bg.default"
      css={`
        border-top: 1px solid ${(props) => props.theme.colors.bg.alt};
      `}
    >
      <SectionWrapper>
        <SectionHeader heading="Resources" />
        <Box mt={3}>
          <RadioButton
            options={[
              volunteerTitle &&
                volunteerTitle[0] &&
                ((volunteerDates && volunteerDates[0]) ||
                  (volunteerDocuments && volunteerDocuments[0]) ||
                  (newsletters && newsletters[0])) && {
                  option: volunteerTitle,
                },
              coordinatorTitle &&
                coordinatorTitle[0] &&
                ((coordinatorDates && coordinatorDates[0]) ||
                  (coordinatorDocuments && coordinatorDocuments[0]) ||
                  (coordinatorNewsletters && coordinatorNewsletters[0])) && {
                  option: coordinatorTitle,
                },
              managerTitle &&
                managerTitle[0] &&
                ((managerDates && managerDates[0]) ||
                  (managerDocuments && managerDocuments[0]) ||
                  (managerNewsletters && managerNewsletters[0])) && {
                  option: managerTitle,
                },
            ]}
            value={dateType}
            onChange={setDateType}
          />
        </Box>
        <Box mt={5}>
          <Accordion
            items={[
              {
                id: "dateAccordion",
                heading: "Dates",
                children: (
                  <FlexGrid gutterX={[3]} gutterY={[3]}>
                    {dateType === volunteerTitle && (
                      <>
                        {volunteerDates.map((date, index) => {
                          if (
                            date.date &&
                            moment(date.date).unix() > moment().unix()
                          ) {
                            return (
                              <Date
                                date={
                                  date.date &&
                                  moment(date.date)
                                    .tz("America/Edmonton")
                                    .format("MMMM Do")
                                }
                                label={date.label}
                                fileLabel={date.file_label}
                                file={date.file && date.file.url}
                                key={"volunteerDate" + index}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                    {dateType === coordinatorTitle && (
                      <>
                        {coordinatorDates.map((date, index) => {
                          if (
                            date.date &&
                            moment(date.date).unix() > moment().unix()
                          ) {
                            return (
                              <Date
                                date={
                                  date.date &&
                                  moment(date.date)
                                    .tz("America/Edmonton")
                                    .format("MMMM Do")
                                }
                                label={date.label}
                                fileLabel={date.file_label}
                                file={date.file && date.file.url}
                                key={"coordinatorDate" + index}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                    {dateType === managerTitle && (
                      <>
                        {managerDates.map((date, index) => {
                          if (
                            date.date &&
                            moment(date.date).unix() > moment().unix()
                          ) {
                            return (
                              <Date
                                date={
                                  date.date &&
                                  moment(date.date)
                                    .tz("America/Edmonton")
                                    .format("MMMM Do")
                                }
                                label={date.label}
                                fileLabel={date.file_label}
                                file={date.file && date.file.url}
                                key={"managerDate" + index}
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                  </FlexGrid>
                ),
              },
              {
                id: "documentAccordion",
                heading: "Documents",
                children: (
                  <>
                    {dateType === volunteerTitle && volunteerDocuments && (
                      <>
                        <FlexGrid gutterX={[2]} gutterY={[2]}>
                          {volunteerDocuments.map((document, index) => (
                            <div key={"volunteerDocument" + index}>
                              <SmallButton
                                as="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={document.label}
                                href={document.link && document.link.url}
                              />
                            </div>
                          ))}
                        </FlexGrid>
                      </>
                    )}
                    {dateType === managerTitle && managerDocuments && (
                      <>
                        <FlexGrid gutterX={[2]} gutterY={[2]}>
                          {managerDocuments.map((document, index) => (
                            <div key={"managerDocument" + index}>
                              <SmallButton
                                as="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={document.label}
                                href={document.link && document.link.url}
                              />
                            </div>
                          ))}
                        </FlexGrid>
                      </>
                    )}
                    {dateType === coordinatorTitle && coordinatorDocuments && (
                      <>
                        <FlexGrid gutterX={[2]} gutterY={[2]}>
                          {coordinatorDocuments.map((document, index) => (
                            <div key={"coordinatorDocument" + index}>
                              <SmallButton
                                as="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={document.label}
                                href={document.link && document.link.url}
                              />
                            </div>
                          ))}
                        </FlexGrid>
                      </>
                    )}
                  </>
                ),
              },
              {
                id: "newsletterAccordion",
                heading: "Newsletters",
                children: (
                  <>
                    {dateType === volunteerTitle && newsletters && (
                      <>
                        <FlexGrid gutterX={[2]} gutterY={[2]}>
                          {newsletters.map((document, index) => (
                            <div key={"newsletter" + index}>
                              <SmallButton
                                as="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={document.label}
                                href={document.link && document.link.url}
                              />
                            </div>
                          ))}
                        </FlexGrid>
                      </>
                    )}
                    {dateType === coordinatorTitle && coordinatorNewsletters && (
                      <>
                        <FlexGrid gutterX={[2]} gutterY={[2]}>
                          {coordinatorNewsletters.map((document, index) => (
                            <div key={"coordinatorNewsletter" + index}>
                              <SmallButton
                                as="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={document.label}
                                href={document.link && document.link.url}
                              />
                            </div>
                          ))}
                        </FlexGrid>
                      </>
                    )}
                    {dateType === managerTitle && managerNewsletters && (
                      <>
                        <FlexGrid gutterX={[2]} gutterY={[2]}>
                          {managerNewsletters.map((document, index) => (
                            <div key={"managerNewsletter" + index}>
                              <SmallButton
                                as="a"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={document.label}
                                href={document.link && document.link.url}
                              />
                            </div>
                          ))}
                        </FlexGrid>
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </Box>
      </SectionWrapper>
    </Box>
  );
}

export default CTA;
