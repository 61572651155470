import React from "react";
import styled from "styled-components";
import { cover, rgba } from "polished";

import { PrismicImage } from "src/components";

const Wrapper = styled.div`
  position: relative;
  width: 50%;
  height: 450px;
  float: left;
  background-color: ${props => rgba(props.theme.colors.bg.reverse, 0.125)};
  @media (max-width: 52em) {
    width: 100%;
  }
`;

const Inner = styled.div`
  ${cover()};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  background-color: ${props => props.theme.colors.bg.default};
`;

const MainCard = ({ heading, link, image }) => (
  <Wrapper>
    {heading && (
      <a href={link} title={heading} target="_blank" rel="noopener noreferrer">
        <Inner>
          {image && (
            <PrismicImage
              image={image}
              sizes="25vw"
              alt={heading}
              style={{ height: "75%", width: "75%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          )}
        </Inner>
      </a>
    )}
  </Wrapper>
);

export default MainCard;
