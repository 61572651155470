import React from "react";
import styled from "styled-components";
import { cover, rgba } from "polished";

import { H3 } from "src/components";

const Wrapper = styled.div`
  position: relative;
  width: 50%;
  height: ${props => (props.large ? 450 : 225)}px;
  float: left;
  background-color: ${props => rgba(props.theme.colors.bg.reverse, 0.125)};
  @media (max-width: 52em) {
    width: 100%;
  }
`;

const Inner = styled.div`
  ${cover()};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  padding: 0 32px;
  background-color: ${props => props.theme.colors.bg.default};
`;

const TitleCard = ({ heading, large }) => (
  <Wrapper large={large}>
    <Inner>
      <H3 children={heading} fluid textAlign="center" />
    </Inner>
  </Wrapper>
);

export default TitleCard;
