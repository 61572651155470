import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { PageHeaderV2, RibbonCTA, SEO } from "src/components";

import Intro from "./_components/_intro";
import Dates from "./_components/_dates";
import FAQs from "./_components/_faqs";

const SponsorPage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        getInvolved: prismicGetInvolved {
          dataString
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.getInvolved.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={"Volunteer"}
            description={
              page.volunteer_description &&
              RichText.asText(page.volunteer_description)
            }
          />
          <PageHeaderV2
            heading={"Volunteer"}
            links={["Join", "Resources", "FAQs"]}
            image={page.volunteer_image}
            color="brand.altPrimary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          <Intro
            subtitle={
              page.volunteer_header && RichText.asText(page.volunteer_header)
            }
            description={
              page.volunteer_description &&
              RichText.render(
                page.volunteer_description,
                linkResolver,
                serializer
              )
            }
            applicationsOpen={page.volunteer_applications_open}
            portalLink={page.portal_link}
            applicationLink={page.application_instructions}
            joinImage={page.volunteer_image_2}
            joinHeader={
              page.application_header &&
              RichText.asText(page.application_header)
            }
            joinDescription={
              page.application_description &&
              RichText.render(
                page.application_description,
                linkResolver,
                serializer
              )
            }
            joinLink={
              page.application_link &&
              page.application_link.url &&
              page.applicationLink
            }
            returningHeader={
              page.returning_header && RichText.asText(page.returning_header)
            }
            returningDescription={
              page.returning_description &&
              RichText.render(
                page.returning_description,
                linkResolver,
                serializer
              )
            }
            returningLink={
              page.returning_link &&
              page.returning_link.url &&
              page.returning_link
            }
            returningLink2={page.returning_link_2}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          <Dates
            volunteerDates={page.volunteer_dates}
            volunteerDocuments={page.volunteer_documents}
            coordinatorDates={page.coordinator_dates}
            coordinatorDocuments={page.coordinator_documents}
            managerDates={page.manager_dates}
            managerDocuments={page.manager_documents}
            newsletters={page.newsletters}
            coordinatorNewsletters={page.coordinator_newsletters}
            managerNewsletters={page.manager_newsletters}
            managerTitle={
              page.manager_title
                ? RichText.asText(page.manager_title)
                : "Managers"
            }
            coordinatorTitle={
              page.coordinator_title
                ? RichText.asText(page.coordinator_title)
                : "Coordinators"
            }
            volunteerTitle={
              page.volunteer_title
                ? RichText.asText(page.volunteer_title)
                : "Frontline"
            }
          />
          <FAQs
            header={page.faq_header && RichText.asText(page.faq_header)}
            faqs={page.volunteer_body}
          />
          <RibbonCTA
            heading={
              page.help_cta_header && RichText.asText(page.help_cta_header)
            }
            body={
              page.help_cta_description &&
              RichText.render(
                page.help_cta_description,
                linkResolver,
                serializer
              )
            }
            buttonText={page.help_cta_button_text}
            buttonLink={page.help_cta_button_link}
            color="brand.primary"
            invert={true}
          />
        </>
      );
    }}
  />
);

export default SponsorPage;
