import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  Accordion,
  MiniAccordion,
  RichText,
  SectionHeader,
  SectionWrapper,
} from "src/components";

const FAQ = ({ faqs, heading, description }) => (
  <SectionWrapper id="faqs" py={[8, 9, 10]} bg="bg.default">
    <section>
      {faqs && (
        <Accordion
          items={faqs.map((faqCategory) => {
            return {
              id: faqCategory.id,
              heading:
                faqCategory.primary.category &&
                PrismicRichText.asText(faqCategory.primary.category),
              children: (
                <MiniAccordion
                  items={faqCategory.items.map((faqItem) => {
                    return {
                      id: faqItem.id,
                      heading: faqItem.question,
                      children: (
                        <RichText
                          children={PrismicRichText.render(
                            faqItem.answer,
                            linkResolver,
                            serializer
                          )}
                          fontSize={[1, 2]}
                          color="text.body"
                        />
                      ),
                    };
                  })}
                  color="text.default"
                />
              ),
            };
          })}
          color="text.default"
        />
      )}
    </section>
  </SectionWrapper>
);

export default FAQ;
